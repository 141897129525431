<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    max-width="1024"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="done"
    scrollable
  >
    <v-card
      v-touch:swipe.left="done"
      class="d-flex flex-column charcoal noscroll"
      tile
    >
      <BaseCloseDialogHeader heading="Nutrients" @cancel="done" />
      <v-card-text class="px-2" v-if="loaded">
        <v-sheet rounded class="hybrid pa-2">
          <v-card-title class="pa-0 paper--text">
            <span :class="textSizeLarge">
              Fats
            </span>
            <v-spacer />
            <v-checkbox
              color="paper"
              dark
              off-icon="mdi-chevron-down-circle-outline"
              on-icon="mdi-chevron-up-circle-outline"
              v-model="fatsDetails"
              label="Breakdown"
              title="Fats Breakdown"
              @change="resetToggles('fats')"
            />
          </v-card-title>
          <v-sheet tile class="transparent pa-0 pt-2">
            <v-row class="" no-gutters>
              <v-col cols="5" class="">
                <span class="paper--text">Total Fats (g)</span>
                <v-text-field
                  solo
                  background-color="hybrid lighten-1"
                  dark
                  color="black"
                  label="Total Fats (g)"
                  v-model="myNutrients['FAT'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="5" class="">
                <span class="paper--text">Saturated Fat (g)</span>
                <v-text-field
                  solo
                  background-color="hybrid lighten-1"
                  dark
                  color="black"
                  label=""
                  v-model="myNutrients['FASAT'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <template v-if="fatsDetails">
                <v-col cols="5" class="">
                  <span class="paper--text">Cholesterol (mg)</span>
                  <v-text-field
                    solo
                    background-color="hybrid lighten-1"
                    dark
                    color="paper"
                    label="Cholesterol (mg)"
                    v-model="myNutrients['CHOLE'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="5" class="">
                  <span class="paper--text">Monosaturated (g)</span>
                  <v-text-field
                    solo
                    background-color="hybrid lighten-1"
                    dark
                    color="paper"
                    label=""
                    v-model="myNutrients['FAMS'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="5" class="mt-n4">
                  <span class="paper--text">Trans Fats (g)</span>
                  <v-text-field
                    solo
                    background-color="hybrid lighten-1"
                    dark
                    color="paper"
                    label="Trans Fats (g)"
                    v-model="myNutrients['FATRN'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="5" class="mt-n4">
                  <span class="paper--text">Polyunsaturated (g)</span>
                  <v-text-field
                    solo
                    background-color="hybrid lighten-1"
                    dark
                    color="paper"
                    label="Polyunsaturated Fat (g)"
                    v-model="myNutrients['FAPU'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </template>
            </v-row>
          </v-sheet>
        </v-sheet>
        <v-sheet rounded class="success darken-1 px-2 pt-2 mt-2">
          <v-card-title class="pa-2 mt-2 paper--text ">
            <span :class="textSizeLarge">
              Proteins
            </span>
            <v-spacer />
          </v-card-title>
          <v-sheet tile class=" success darken-1 pa-2">
            <v-row>
              <v-col cols="6" class="mt-">
                <span class="paper--text">Proteins</span>
                <v-text-field
                  solo
                  background-color="success"
                  dark
                  color="black"
                  label="Proteins"
                  v-model="myNutrients['PROCNT'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
            </v-row>
          </v-sheet>
        </v-sheet>
        <v-sheet rounded class="gold darken-1 pa-2 mt-2">
          <v-card-title class="pa-0 pr-2 mt-2 paper--text">
            <span :class="textSizeLarge">
              Carbs
            </span>
            <v-spacer />
            <v-checkbox
              color="paper"
              dark
              off-icon="mdi-chevron-down-circle-outline"
              on-icon="mdi-chevron-up-circle-outline"
              v-model="carbsDetails"
              label="Breakdown"
              title="Carbs Breakdown"
              @change="resetToggles('carbs')"
            />
          </v-card-title>
          <v-sheet tile class="transparent pa-0 pt-2">
            <v-row no-gutters>
              <v-col cols="5" class="mt-n4">
                <span class="paper--text">Total Carbs (g)</span>
                <v-text-field
                  solo
                  background-color="gold"
                  dark
                  color="black"
                  label="Total Carbs (g)"
                  v-model="myNutrients['CHOCDF'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="5" class="mt-n4">
                <span class="paper--text">Fiber (g)</span>
                <v-text-field
                  solo
                  background-color="gold"
                  dark
                  color="black"
                  label="Fiber (g)"
                  v-model="myNutrients['FIBTG'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight-gram"
                  @focus="$event.target.select()"
                />
              </v-col>
              <template v-if="carbsDetails">
                <v-col cols="5" class="mt-n4">
                  <span class="paper--text">Total Sugar (g)</span>
                  <v-text-field
                    solo
                    background-color="gold"
                    dark
                    color="black"
                    label="Total Sugar (g)"
                    v-model="myNutrients['SUGAR'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="5" class="mt-n4">
                  <span class="paper--text">Sugar Alcohol (g)</span>
                  <v-text-field
                    solo
                    background-color="gold"
                    dark
                    color="black"
                    label="Sugar Alcohol (g)"
                    v-model="myNutrients['Sugar.alcohol'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="5" class="mt-n4">
                  <span class="paper--text">Added Sugar (g)</span>
                  <v-text-field
                    solo
                    background-color="gold"
                    dark
                    color="black"
                    label="Added Sugar (g)"
                    v-model="myNutrients['SUGAR.added'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="5" class="mt-n4">
                  <span class="paper--text">Net Carbs (g)</span>
                  <v-text-field
                    solo
                    background-color="gold"
                    dark
                    color="black"
                    label="Net Carbs (g)"
                    v-model="myNutrients['CHOCDF.net'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight-gram"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </template>
            </v-row>
          </v-sheet>
        </v-sheet>

        <v-sheet rounded class="charcoalTileMenu transparent pa-2 pt-0 mt-2">
          <v-card-title class="px-2 py-0 paper--text">
            <span :class="textSizeLarge">
              Minerals
            </span>
            <v-spacer />
            <v-checkbox
              color="paper"
              dark
              off-icon="mdi-chevron-down-circle-outline"
              on-icon="mdi-chevron-up-circle-outline"
              v-model="mineralsDetails"
              :label="mineralsDetails ? 'Less' : 'More'"
              title="More Minerals"
              @change="resetToggles('minerals')"
            />
          </v-card-title>
          <v-sheet rounded class=" transparent pa-2 pt-4">
            <v-row dense>
              <v-col cols="6" class="mt-n4">
                <span class="paper--text">Sodium (NA, mg)</span>
                <v-text-field
                  solo
                  background-color="charcoal lighten-1"
                  dark
                  color="progressActive"
                  label="Sodium (NA, mg)"
                  ref="proteins"
                  v-model="myNutrients['NA'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-spacer />
              <v-col cols="6" class="mt-n4">
                <span class="paper--text">Calcium (CA, mg)</span>
                <v-text-field
                  solo
                  background-color="charcoal lighten-1"
                  dark
                  color="progressActive"
                  label="Calcium (CA, mg)"
                  v-model="myNutrients['CA'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <template v-if="mineralsDetails">
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Iron (FE, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    label="Iron (FE, mg)"
                    ref="proteins"
                    v-model="myNutrients['FE'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Potassium (K, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    label="Potassium (K, mg)"
                    ref="proteins"
                    v-model="myNutrients['K'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Phosphorus (P, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    label="Phosphorus (P, mg)"
                    ref="proteins"
                    v-model="myNutrients['P'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Magnesium (MG, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    label="Magnesium (MG, mg)"
                    ref="proteins"
                    v-model="myNutrients['MG'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-spacer />
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Zinc (ZN, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    label="Zinc (ZN, mg)"
                    ref="proteins"
                    v-model="myNutrients['ZN'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </template>
            </v-row>
          </v-sheet>
        </v-sheet>
        <v-sheet rounded class="charcoalTileMenu transparent pa-2 pt-0 mt-2">
          <v-card-title class="px-2 py-0 paper--text">
            <span :class="textSizeLarge">
              Vitamins
            </span>
            <v-spacer />
            <v-checkbox
              color="paper"
              dark
              off-icon="mdi-chevron-down-circle-outline"
              on-icon="mdi-chevron-up-circle-outline"
              v-model="vitaminsDetails"
              :label="vitaminsDetails ? 'Less' : 'More'"
              title="More Vitamins"
              @change="resetToggles('vitamins')"
            />
          </v-card-title>
          <v-sheet rounded class="transparent pa-2 pt-4">
            <v-row dense>
              <v-col cols="6" class="">
                <span class="paper--text">Vitamin D (D2+D3, µg)</span>
                <v-text-field
                  solo
                  background-color="charcoal lighten-1"
                  dark
                  color="progressActive"
                  v-model="myNutrients['VITD'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>
              <v-col cols="6" class="">
                <span class="paper--text">Vitamin B-12 (µg)</span>
                <v-text-field
                  solo
                  background-color="charcoal lighten-1"
                  dark
                  color="progressActive"
                  v-model="myNutrients['VITB12'].quantity"
                  class="enlarged-input-small"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  prepend-inner-icon="mdi-weight"
                  @focus="$event.target.select()"
                />
              </v-col>

              <template v-if="vitaminsDetails">
                <v-col cols="6" class="">
                  <span class="paper--text">Folate, DFE (B9, µg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['FOLDFE'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="">
                  <span class="paper--text">Folate, food (B9, µg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['FOLFD'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="">
                  <span class="paper--text">Folic acid (B9, µg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['FOLAC'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="">
                  <span class="paper--text">Niacin (B3, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['NIA'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Riboflavin (B2, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['RIBF'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Thiamin (B1, mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['THIA'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Vitamin B-6 (mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['VITB6A'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Vitamin A, RAE (µg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['VITA_RAE'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Vitamin C (mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['VITC'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Vitamin E (mg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['TOCPHA'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
                <v-col cols="6" class="mt-n4">
                  <span class="paper--text">Vitamin K (µg)</span>
                  <v-text-field
                    solo
                    background-color="charcoal lighten-1"
                    dark
                    color="progressActive"
                    v-model="myNutrients['VITK1'].quantity"
                    class="enlarged-input-small"
                    type="number"
                    pattern="[0-9]*"
                    inputmode="decimal"
                    prepend-inner-icon="mdi-weight"
                    @focus="$event.target.select()"
                  />
                </v-col>
              </template>
            </v-row>
          </v-sheet>
        </v-sheet>
      </v-card-text>

      <v-card-actions class="px-0 mt- py-0">
        <v-spacer />
        <v-btn
          tile
          block
          height="90"
          title="Done"
          tonal
          class="charcoal--text "
          color="progressActive"
          @click="done"
        >
          <v-icon x-large color="black">
            mdi-checkbox-multiple-marked-circle
          </v-icon>
          <span class="text-h5">Done</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import myNutrients from '@/json/nutrients.json'

export default {
  components: {},
  name: 'AddNutrients',
  mixins: [validationMixin, util],
  data() {
    return {
      loaded: false,
      myNutrients: null,
      loadedNutrients: myNutrients,
      initialCarbs: 0,
      initialFat: 0,
      initialProtein: 0,
      initialSugara: 0,
      initialFiber: 0,
      fatsDetails: false,
      carbsDetails: false,
      vitaminsDetails: false,
      mineralsDetails: false
    }
  },
  beforeMount() {},
  mounted() {
    this.reset()
    this.loaded = true
    this.setNutrients()
    this.$nextTick(() => {
      window.addEventListener('message', this.receiveMessage)
    })
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    nutrients: {
      type: Object
    }
  },
  computed: {
    ...appConfig
  },
  watch: {
    show(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (!val) this.reset()
      else {
        this.scrollToTop()
        if (this.loaded) this.setNutrients()
      }
    }
  },

  methods: {
    resetToggles(what) {
      if (what != 'fats') this.fatsDetails = false
      if (what != 'carbs') this.carbsDetails = false
      if (what != 'minerals') this.mineralsDetails = false
      if (what != 'vitamins') this.vitaminsDetails = false
    },
    done() {
      let changed =
        this.initialCarbs != this.myNutrients['CHOCDF'].quantity ||
        this.initialFat != this.myNutrients['FAT'].quantity ||
        this.initialProtein != this.myNutrients['PROCNT'].quantity ||
        this.initialSugara != this.myNutrients['Sugar.alcohol'].quantity ||
        this.initialFiber != this.myNutrients['FIBTG'].quantity

      this.$emit('done', { nutrients: this.myNutrients, changed: changed })
    },
    setNutrients() {
      this.myNutrients = Object.assign({}, this.myNutrients, this.nutrients)
      Object.keys(this.myNutrients).forEach(element => {
        this.myNutrients[element].quantity = this.twoDigits(
          this.myNutrients[element].quantity
        )
      })
      this.initialCarbs = this.myNutrients['CHOCDF'].quantity
      this.initialFat = this.myNutrients['FAT'].quantity
      this.initialProtein = this.myNutrients['PROCNT'].quantity
      this.initialSugara = this.myNutrients['Sugar.alcohol'].quantity
      this.initialFiber = this.myNutrients['FIBTG'].quantity
      this.loaded = true
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.done()
      }
    },
    reset() {
      this.myNutrients = JSON.parse(JSON.stringify(this.loadedNutrients))
      this.initialCarbs = 0
      this.initialFat = 0
      this.initialProtein = 0
      this.initialSugara = 0
      this.initialFiber = 0
    }
  }
}
</script>
<style scoped>
.my-input-container {
  position: relative;
}

.my-input-label {
  position: absolute;
  top: 10px;
  left: 10px;
  color: rgba(255, 255, 255, 0.9); /* Adjust the color as needed */
}
.edamam {
  z-index: 99;
  position: absolute;
  left: 160px;
  top: 6px;
}
</style>
